<template>
  <div>
    <h5>
      <strong>PRIVACY POLICY</strong>
    </h5>
    <p>
      Effective Date: 2023-01-24
    </p>
    <p>
      SellerXray Inc. owns and operates the SellerXray business, including the platform, analytics, information, applications, websites and other products and services (collectively, the <strong>“Services”</strong>). All references to “we”, “us”, this “website” or this “site” refer to SellerXray. This Privacy Policy is supplemented by and subject to the Terms & Conditions.
    </p>
    <p>
      Our Services are designed for businesses and are not intended
      for personal, family or household use. Accordingly, we treat all
      personal information covered by this Privacy Policy, including
      information about any visitors to our website, as pertaining to
      individuals acting as business representatives, rather than in
      their personal capacity.
    </p>
    <p>
      Your privacy is valued and respected at SellerXray (“we”, “our”,
      “us”). This Privacy Policy (“Privacy Policy”) describes the
      types of information, including Personal Data, that we gather
      from you when you use our website (including
      www.sellerxray.com), apps, services (our “Service”), as well as
      your choices regarding use and disclosure of your Personal Data.
      As used in this Privacy Policy, the term “Personal Data”
      (“Personal Data”) means information that identifies you directly
      or indirectly, by reference to identifier(s) such as your name,
      identification number, location data, online identifier or one
      or more factors specific to your physical, physiological,
      genetic, mental, economic, cultural or social identity.
    </p>
    <p>
      By accessing our Service, you agree to be bound by this Privacy
      Policy. If you do not agree to the terms of this Privacy Policy,
      you cannot use our Service. Each time you use our Service, the
      current version of the Privacy Policy will apply. Accordingly,
      whenever you use our Service, you should check the date of this
      Privacy Policy (which appears at the top) and review any changes
      since the last version. This Privacy Policy is applicable to all
      Service visitors, registered users, and all other users of our
      Service.
    </p>
    <p>
      Further, you acknowledge that this Privacy Policy is part of our
      Terms of Service , which is incorporated by reference, and by
      accessing or using our Service, you agree to be bound by the
      Terms of Service. If you do not agree to our Terms of Service,
      you cannot use our Service.
    </p>
    <p>
      If you have any questions about our privacy practices, please
      refer to the end of this Privacy Policy for information on how
      to contact us.
    </p>
    <p>
      Residents of the European Economic Area (“EEA”), which includes
      the member states of the European Union (“EU”), should consult
      the sections of this policy relating to the “EEA Residents” and
      “International Data Transfers” for provisions that may apply to
      them.
    </p>
    <p>
      California residents should consult the section titled “Your
      California Privacy Rights” for rights that apply to them.
    </p>
    <p>
      We reserve the right to change this Privacy Policy at any time.
      All changes are effective on the date listed at the top of this
      page and will apply to all information that we have about you.
      Your continued use of the Services after any change is posted
      indicates your acceptance of this Privacy Policy and your
      continued consent to our processing of your Personal Information
      in the manner set forth herein.  If at any point you do not
      agree to any portion of the Privacy Policy, you should
      immediately stop using the Services.  Because this Privacy
      Policy contains legal obligations we encourage you to review
      this Privacy Policy carefully.  If you have any questions
      concerning this Privacy Policy or our information collection,
      storage and disclosure practices, please contact us at
      info@sellerxray.com.
    </p>
    <h5>
      INFORMATION WE COLLECT
    </h5>
    <ul>
      <li>
        Personal Information. “Personal Information” is information that may be used to directly or indirectly identify an individual (which in some cases, may include certain device information). The Personal Information we collect may include your (a) name, postal address, email address, and phone number; (b) Internet Protocol (IP) address, device ID or other persistent identifiers tied to your computer or device; and (c) information about your third party accounts (“Third Party Accounts”) you may choose to provide us for integration and analytics purposes.
        In general, Personal Information we collect includes, but
        is not limited to:
      </li>
      <ul>
        <li>Personal Information needed for you to be able to use the Services, including for establishing an account, logging in, paying for subscription to the Services, and linking your Third Party Accounts;</li>
        <li>Personal Information to contact and respond to you, including to provide you with results of our analytics services, reply to your inquiries, and keep in touch with you regarding features or matters of interest regarding the Services;</li>
        <li>Un-identifiable and aggregated Personal Information pertaining to your visits to and use of the Services and Site that help us maintain the appropriate features, functionality and user experience</li>
      </ul>
      <li>
        Usage Data. <strong>“Usage Data”</strong> is information passively or automatically collected by us through your use of the
        Services.  Usage Data may be collected using cookies, web
        beacons, page tags or similar tools.  All Usage Data is
        anonymous transactional data that is not associated with
        any users as individuals.  Such Usage Data may include:
        your Internet Protocol (IP) address, mobile identifiers,
        browser type, and internet service provider (ISP); your
        operating system; which of our web pages you access and how
        frequently you access them; referral or exit pages; click
        stream data; and the dates and times that you visit the
        Services or Site.
      </li>
      <li>
        Customer Data In order to provide you with our services, and with your permission (which you are providing if you link any of your Third Party Platforms to your Company account), we may collect and process data regarding your customers and their use of your services (<strong>“Customer Data”</strong>). How we treat that Customer Data is set forth in the “Customer Data” section of our Terms & Conditions. As set forth in more detail in our Terms & Conditions, you represent and warrant that all Customer Data was collected by you in accordance with your privacy policy and all applicable laws and that you have the right to provide us with such Customer Data for the purposes set forth in the Terms & Conditions. For clarity, your Third Party Platform accounts are governed by their own privacy policies and are not under the control of Company.
      </li>
    </ul>

    <h5><strong>HOW AND WHEN WE COLLECT INFORMATION</strong></h5>
    <ul>
      <li>
        Personal Information. We collect Personal Information at the time you provide it to us. We collect Personal Information through sign-up forms and as part of your registration for an account, product, or service, promotion, or contest from this website. In addition, we collect personal information from communications with site
        visitors as well as from third-party outside sources
        including data brokers and aggregators.
      </li>
      <li>
        Usage Data. We use cookies when you sign in to keep track
        of your personal session.  We also use cookies to track
        your activity on the Services, including your interactions
        with emails we send, and via social media platforms,
        third-party platforms, applications, integrations, and
        services of our marketing partners to provide you with the
        optimal experience.   This data may be passively or
        automatically collected (that is, gathered without your
        actively providing the information) using various analytics
        and reporting technologies, such as cookies, web beacons,
        locally stored objects, and mobile device identifiers and
        SDKs, and other similar methodologies as well as similar
        technologies developed in the future.
      </li>
      <li>
        “Do Not Track” Requests. Some Web browsers have a “Do Not Track” feature that signals to websites that you do not want to have your online activity tracked. Because each browser communicates “Do Not Track” signals differently, we do not respond to “Do Not Track” signals at this time.
      </li>
    </ul>
    <h5><strong>HOW WE USE INFORMATION</strong></h5>
    <ul>
      <li>
        We may use your Personal Information and Usage Data to take actions you request and for the basic purposes of the Services. We may use your Personal Information in connection with other products or services we may offer, with our internal reporting for this site (such as security assessments), or to contact you with promotions regarding other products or services offered by us, our affiliated entities or our third party partners. We may, for example, use aggregated Usage Data information to optimize the Services or to evaluate the success of particular marketing
        and advertising campaigns or search engine optimization
        strategies.
      </li>
      <li>
        We may also send you messages related to certain features
        or your activity on this site. We may also send you news or
        updates about changes to our Services. By default, you will
        receive these messages via email.
      </li>
      <li>
        We may use publicly available Personal Information posted on social media profile information including photos for purposes of assisting us, and our marketing partners with marketing and advertising activities and with contact management.
      </li>
      <li>
        We may use Usage Data without restriction in our sole discretion for administrative and optimization purposes, such as to improve the Services. We may combine and use the Personal Information and Usage Data we collect from all services and products offered by us and our Affiliated Entities over various websites to provide, protect, and improve them, and to develop and offer new services and products. We will treat you as a single user of these combined services and products.
      </li>
    </ul>
    <h5>
      HOW AND WHEN WE SHARE AND DISCLOSE INFORMATION
    </h5>
    <ul>
      <li>General Disclosure Policy. We may share and disclose your Personal Information as described below. We may share and disclose Usage Data without restriction, such as in the ways described below.</li>
      <li>
        Service Providers. We may provide access to your Personal Information and Usage Data to trusted service providers that assist us with the operation and maintenance of the Service. For example, we may contract with third parties to facilitate purchases from the Services, process payments, host our servers, provide security, and provide production, fulfillment, optimization, analytics, reporting, and
        software maintenance and development services. Our service
        providers will be given access to your information only as
        is reasonably necessary to provide the services for which
        they are contracted.
      </li>
      <li>
        Successors. If we sell or otherwise transfer part or all of our business or assets to another organization, such as in the course of an acquisition, merger, bankruptcy or liquidation, we may transfer your Personal Information and Usage Data.
      </li>
      <li>
        Legal Process, Enforcement and Security Notice. We may disclose your Personal Information and Usage Data if we have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary (i) to satisfy any applicable law, regulation, legal process or enforceable governmental request (such as for example, to comply with a subpoena or court order), (ii) to detect, prevent, and address fraud or other illegal activity, and (iii) to investigate, respond to, or enforce violations of our rights or the security of this site.
      </li>
      <li>
        With Your Consent. We may share your Personal Information with other parties with your consent.
      </li>
      <li>
        Analytics Partners. We may participate with third party analytics partners to monitor and analyze Web traffic and to keep track of user behavior on this site.
      </li>
      <li>
        Transfer Outside Country of Residence. In general, any Personal Information we may collect may be stored and processed in our servers located in the United States or in any other country in which we, or our affiliates, subsidiaries, or agents maintain facilities. By using this site, you consent to any such transfer of Personal Information outside your country of residence to any such location.
      </li>
    </ul>
    <h5>CHANGING AND DELETING PERSONAL INFORMATION</h5>
    <ul>
      <li>
        Under certain laws, including as described below with respect to the GDPR and CCPA, you may have the right to: obtain confirmation that we hold Personal Information about you, request access to and receive information about the Personal Information we maintain about you, receive copies of the Personal Information we maintain about you, update and correct inaccuracies in your Personal Information, object to the continued processing of your Personal Information, and have the Personal Information blocked, anonymized or deleted, as appropriate. The right to access Personal Information may be limited in some circumstances by local law, including as described above under California law. If you qualify, in order to exercise these rights, please contact us as described under <strong>“Contact Us”</strong>.
      </li>
      <li>
        You may opt out of promotional communications by sending us an email at the email address below under <strong>“Contact Us”</strong>.
      </li>
      <li>
        We may ask you to provide additional information for identity verification purposes, or to verify that you are in possession of an applicable email account.
      </li>
      <li>
        Please understand, however, that we reserve the right to retain an archive of such Personal Information for a commercially reasonable time to ensure that its deletion does not affect the integrity of our data; and we further reserve the right to retain an anonymous version of such Information.
      </li>
    </ul>
    <h5>TERMS FOR SPECIFIC TYPES OF USERS</h5>
    <ul>
      <li>EEA Residents</li>
      <ul>
        <li>
          This section of the Privacy Policy is applicable to
          residents of the EEA.  The EEA consists of the member
          states of the EU, i.e., Austria, Belgium, Bulgaria,
          Croatia, Republic of Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, and the United Kingdom, and Iceland, Liechtenstein, and Norway. This section also applies to residents of Switzerland and the United Kingdom. Residents of the EEA, Switzerland and the United Kingdom are referred to here as <strong>“EEA Residents”</strong>.
        </li>
        <li>
          Since May 25, 2018, all processing of Personal Information of EEA Residents is performed by us in accordance with the General Data Protection Regulation (2016/679) of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons regarding the processing of Personal Information and on the free movement of such data (<strong>“GDPR”</strong>).
        </li>
        <li>
          Under the GDPR, we are the controller and a processor of the Personal Information of EEA Residents. Our purpose for collecting and processing Personal Information from EEA Residents is to obtain contact information, to substantiate such information and to provide the Services. The legal basis for collecting Personal Information is your consent. You may withdraw consent for obtaining such communications by following the <strong>“Unsubscribe”</strong> instructions on any communication or by contacting us at info@sellerxray.com.
        </li>
        <li>
          If you are a resident of the EEA and believe that we have Personal Information about you, and you wish to access or correct the Personal Information that we have about you or have any questions relating to the processing of your Personal Information, please
          contact us at info@sellerxray.com with the subject
          line <strong>“GDPR Data.”</strong>
        </li>
        <li>
          International Data Transfers. If you are resident
          outside the United States, including in the EEA, we transfer Personal Information provided by you for processing in the United States. Under the GDPR, we are considered a <strong>“controller”</strong> and a <strong>“co-processor”</strong> of the Personal Information of EEA Residents. By providing Personal Information to us for the purpose of setting up an account, obtaining Services, or adding yourself to our contact lists, you consent to the transfer of your Personal Information to the United States. The transfer of your Personal Information to the United States is necessary for the performance of a contract between you and us for obtaining Services.
        </li>
      </ul>
      <li>
        California Consumers
      </li>
      <ul>
        <li>This section pertains to the rights of individuals or
          households in California (<strong>“California consumers”</strong>).
        </li>
        <li>
          Under certain circumstances, California Civil Code
          Section 1798.83 states that, upon receipt of a request
          by a California consumer, a business may be required
          to provide detailed information regarding how that
          business has shared that customer’s Personal
          Information with third parties for direct marketing
          purposes. However, the foregoing does not apply to
          businesses like ours that do not disclose Personal
          Information to third parties for direct marketing
          purposes.
        </li>
        <li>
          The CCPA (California Civil Code Section 1798.100 et seq.) provides California consumers with additional rights regarding Personal Information that identifies, relates to, describes, is capable of being associated
          with, or could reasonably be linked, directly or
          indirectly with a particular consumer or household.
          The categories of Personal Information we collect are
          generally described above but differ for individual
          consumers depending on the services used by such
          consumers.
        </li>
        <li>
          Under the CCPA, qualifying California consumers may have the following rights:
        </li>
        <ul>
          <li>
            Right to Know and Right to Delete.
          </li>
          <li>
            A California consumer has the right to request
            that we disclose what Personal Information we
            collect, use, disclose and sell. A California
            consumer also has the right to submit requests to
            delete Personal Information.
          </li>
          <li>
            When we receive a valid request to know or delete from a California consumer, we will confirm receipt of the request within 10 days and provide information about how we will process the request, including our verification process. We will respond to such requests within 45 days.
          </li>
          <li>
            Right for Disclosure of Information.
          </li>
          <li>
            A California consumer may also submit requests
            that we disclose specific types or categories of
            Personal Information that we collect.
          </li>
          <li>
            Under certain circumstances, we will not provide such information, including where the disclosure
            creates a substantial, articulable and
            unreasonable risk to the security of that
            Personal Information, customers’ account with us,
            or the security of our systems or networks. We
            also will not disclose California consumers’
            social security numbers, driver’s license numbers
            or other government-issued identification
            numbers, financial account numbers, any health
            insurance or medical identification numbers, or
            account passwords and security questions and
            answers.
          </li>
        </ul>
        <li>
          Submitting Requests
        </li>
        <li>
          If you are a California consumer and would like to
          make any requests under the CCPA, please see the
          “Changing and Deleting Personal Information” the
          section.
        </li>
        <li>
          Verifying Requests. If we receive any request we will use a two-step process for online requests where the California consumer must first, clearly submit the request and then second, separately confirm the request. We will use other appropriate measures to verify requests received by mail or telephone.
        </li>
        <li>
          To verify a request, a California consumer must provide a business with sufficient information to identify the consumer, such as name, e-mail address, home or work address, or other such information that is on record with us so that we can match such information to the Personal Information that we maintain. Do not provide social security numbers, driver’s license numbers, account numbers, credit or debit card numbers, medical information or health information with requests. If requests are unclear or submitted through means other than outline above, we will provide the California consumer with specific directions on how to submit the request or remedy any deficiencies. If we cannot verify the identity of the requestor, we may deny the request.
        </li>
      </ul>
      <li>
        Children under 13
      </li>
      <ul>
        <li>
          We are committed to preserving online privacy for all of its website visitors, including children. Consistent with the Children’s Online Privacy
          Protection Act (COPPA), we do not knowingly collect
          any personal information from children under the age
          of 13, and we delete any such information we become
          aware of. If you are a parent or guardian and you
          believe your child has provided us with Personal
          Information, please contact us at “please contact our
          site as provided below under “Contact Us,” and be sure
          to include in your message the same login information
          that your child submitted.
        </li>
      </ul>
    </ul>
    <p><strong>CONTACT US</strong></p>
    <p><strong>info@sellerxray.com</strong></p>
    <p><strong>555-555-5555</strong></p>
    <p><strong>Addressxxx</strong></p>
  </div>
</template>

<script>
export default {
  name: 'Static',
}
</script>

<style scoped>

</style>
